<template>
  <div class="login-container">
    <svg width="123" height="51" viewBox="0 0 123 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.9453 15.8403C18.9453 21.4936 16.172 24.3203 10.6253 24.3203C5.07865 24.3203 2.30531 21.4936 2.30531 15.8403V2.43231C2.30531 1.94165 2.47598 1.52564 2.81731 1.18431C3.17998 0.821645 3.60665 0.640312 4.09731 0.640312C4.58798 0.640312 5.00398 0.821645 5.34531 1.18431C5.70798 1.52564 5.88931 1.94165 5.88931 2.43231V16.0003C5.88931 17.8776 6.27331 19.243 7.04131 20.0963C7.83065 20.9496 9.04665 21.3763 10.6893 21.3763C12.332 21.3763 13.5373 20.9496 14.3053 20.0963C15.0946 19.243 15.4893 17.8776 15.4893 16.0003V2.36831C15.4893 1.89898 15.66 1.49365 16.0013 1.15231C16.3426 0.810979 16.748 0.640312 17.2173 0.640312C17.6866 0.640312 18.092 0.810979 18.4333 1.15231C18.7746 1.49365 18.9453 1.89898 18.9453 2.36831V15.8403Z" fill="#3A3A3A"/>
      <path d="M26.8193 23.4883C26.478 23.8296 26.0726 24.0003 25.6033 24.0003C25.134 24.0003 24.7286 23.8296 24.3873 23.4883C24.046 23.147 23.8753 22.7416 23.8753 22.2723V2.36831C23.8753 1.89898 24.046 1.49365 24.3873 1.15231C24.7286 0.810979 25.134 0.640312 25.6033 0.640312C26.734 0.640312 27.598 1.14165 28.1953 2.14431L37.2833 18.0483C37.2833 18.0696 37.3046 18.0803 37.3473 18.0803C37.3686 18.0803 37.3793 18.0696 37.3793 18.0483V2.36831C37.3793 1.89898 37.55 1.49365 37.8913 1.15231C38.2326 0.810979 38.638 0.640312 39.1073 0.640312C39.5766 0.640312 39.982 0.810979 40.3233 1.15231C40.6646 1.49365 40.8353 1.89898 40.8353 2.36831V22.2723C40.8353 22.7416 40.6646 23.147 40.3233 23.4883C39.982 23.8296 39.5766 24.0003 39.1073 24.0003C37.9766 24.0003 37.1126 23.499 36.5153 22.4963L27.3953 6.59231C27.3953 6.57098 27.3846 6.56031 27.3633 6.56031C27.342 6.56031 27.3313 6.57098 27.3313 6.59231V22.2723C27.3313 22.7416 27.1606 23.147 26.8193 23.4883Z" fill="#3A3A3A"/>
      <path d="M47.9181 24.0003C47.4274 24.0003 47.0007 23.819 46.6381 23.4563C46.2754 23.0936 46.0941 22.667 46.0941 22.1763V2.46431C46.0941 1.97364 46.2754 1.54698 46.6381 1.18431C47.0007 0.821645 47.4274 0.640312 47.9181 0.640312H58.7341C59.1394 0.640312 59.4807 0.778979 59.7581 1.05631C60.0354 1.33364 60.1741 1.67498 60.1741 2.08031C60.1741 2.48565 60.0354 2.82698 59.7581 3.10431C59.4807 3.38164 59.1394 3.52031 58.7341 3.52031H49.9021C49.7101 3.52031 49.6141 3.60564 49.6141 3.77631V9.88831C49.6141 10.0803 49.7101 10.1763 49.9021 10.1763H58.1581C58.5421 10.1763 58.8621 10.315 59.1181 10.5923C59.3954 10.8483 59.5341 11.1683 59.5341 11.5523C59.5341 11.9363 59.3954 12.267 59.1181 12.5443C58.8621 12.8003 58.5421 12.9283 58.1581 12.9283H49.9021C49.7101 12.9283 49.6141 13.0243 49.6141 13.2163V20.8323C49.6141 21.0243 49.7101 21.1203 49.9021 21.1203H58.7341C59.1394 21.1203 59.4807 21.259 59.7581 21.5363C60.0354 21.8136 60.1741 22.155 60.1741 22.5603C60.1741 22.9656 60.0354 23.307 59.7581 23.5843C59.4807 23.8616 59.1394 24.0003 58.7341 24.0003H47.9181Z" fill="#3A3A3A"/>
      <path d="M70.8616 13.6003C68.4296 12.7683 66.6696 11.7763 65.5816 10.6243C64.5149 9.45098 63.9816 8.04298 63.9816 6.40031C63.9816 4.52298 64.5789 3.04031 65.7736 1.95231C66.9682 0.864313 68.6109 0.320312 70.7016 0.320312C72.6642 0.320312 74.3922 0.554979 75.8856 1.02431C76.3336 1.17365 76.6962 1.46165 76.9736 1.88831C77.2722 2.29364 77.4216 2.75231 77.4216 3.26431C77.4216 3.62698 77.2616 3.90431 76.9416 4.09631C76.6216 4.28831 76.2909 4.30965 75.9496 4.16031C74.3282 3.47765 72.6856 3.13631 71.0216 3.13631C69.8696 3.13631 68.9842 3.42431 68.3656 4.00031C67.7469 4.57631 67.4376 5.37631 67.4376 6.40031C67.4376 7.23231 67.7256 7.98965 68.3016 8.67231C68.8776 9.33365 69.7309 9.85631 70.8616 10.2403C73.6776 11.179 75.6296 12.2243 76.7176 13.3763C77.8269 14.5283 78.3816 15.9896 78.3816 17.7603C78.3816 19.915 77.7202 21.547 76.3976 22.6563C75.0749 23.7656 73.1229 24.3203 70.5416 24.3203C68.8562 24.3203 67.3309 24.0643 65.9656 23.5523C64.9629 23.147 64.4616 22.3576 64.4616 21.1843C64.4616 20.8216 64.6216 20.555 64.9416 20.3843C65.2616 20.2136 65.5816 20.2136 65.9016 20.3843C67.2242 21.0883 68.6642 21.4403 70.2216 21.4403C73.3149 21.4403 74.8616 20.2136 74.8616 17.7603C74.8616 16.8216 74.5522 16.0216 73.9336 15.3603C73.3362 14.699 72.3122 14.1123 70.8616 13.6003Z" fill="#3A3A3A"/>
      <path d="M91.8116 24.3203C88.5689 24.3203 85.9662 23.275 84.0036 21.1843C82.0622 19.0936 81.0916 16.139 81.0916 12.3203C81.0916 8.54431 82.0302 5.60031 83.9076 3.48831C85.8062 1.37631 88.4409 0.320312 91.8116 0.320312C93.2622 0.320312 94.5849 0.426979 95.7796 0.640312C96.2062 0.725646 96.5582 0.949645 96.8356 1.31231C97.1129 1.65365 97.2516 2.04831 97.2516 2.49631C97.2516 2.88031 97.0916 3.18965 96.7716 3.42431C96.4729 3.63765 96.1422 3.69098 95.7796 3.58431C94.7769 3.32831 93.5609 3.20031 92.1316 3.20031C89.8276 3.20031 88.0036 4.00031 86.6596 5.60031C85.3369 7.17898 84.6756 9.41898 84.6756 12.3203C84.6756 15.2216 85.3582 17.4723 86.7236 19.0723C88.0889 20.651 89.8916 21.4403 92.1316 21.4403C93.5396 21.4403 94.8089 21.3016 95.9396 21.0243C96.3022 20.9176 96.6329 20.9816 96.9316 21.2163C97.2516 21.451 97.4116 21.7603 97.4116 22.1443C97.4116 22.5923 97.2729 22.9976 96.9956 23.3603C96.7182 23.7016 96.3556 23.915 95.9076 24.0003C94.6489 24.2136 93.2836 24.3203 91.8116 24.3203Z" fill="#3A3A3A"/>
      <path d="M116.172 5.50431C114.998 3.96831 113.324 3.20031 111.148 3.20031C108.972 3.20031 107.286 3.96831 106.092 5.50431C104.918 7.01898 104.332 9.29098 104.332 12.3203C104.332 15.3496 104.918 17.6323 106.092 19.1683C107.286 20.683 108.972 21.4403 111.148 21.4403C113.324 21.4403 114.998 20.683 116.172 19.1683C117.366 17.6323 117.964 15.3496 117.964 12.3203C117.964 9.29098 117.366 7.01898 116.172 5.50431ZM118.732 21.1523C116.876 23.2643 114.348 24.3203 111.148 24.3203C107.948 24.3203 105.409 23.2643 103.532 21.1523C101.676 19.0403 100.748 16.0963 100.748 12.3203C100.748 8.54431 101.676 5.60031 103.532 3.48831C105.409 1.37631 107.948 0.320312 111.148 0.320312C114.348 0.320312 116.876 1.37631 118.732 3.48831C120.609 5.60031 121.548 8.54431 121.548 12.3203C121.548 16.0963 120.609 19.0403 118.732 21.1523Z" fill="#3A3A3A"/>
      <path d="M3.44531 50.1203C2.48531 50.1203 1.74531 49.8483 1.22531 49.3043C0.705313 48.7523 0.445312 47.9443 0.445312 46.8803C0.445312 45.8323 0.697313 45.0323 1.20131 44.4803C1.70531 43.9203 2.41331 43.6403 3.32531 43.6403C3.74931 43.6403 4.15331 43.6683 4.53731 43.7243C4.68931 43.7483 4.81331 43.8203 4.90931 43.9403C5.01331 44.0603 5.06531 44.2003 5.06531 44.3603C5.06531 44.4963 5.00931 44.6043 4.89731 44.6843C4.79331 44.7643 4.67331 44.7883 4.53731 44.7563C4.18531 44.6763 3.81331 44.6363 3.42131 44.6363C2.86931 44.6363 2.45331 44.8163 2.17331 45.1763C1.90131 45.5283 1.76531 46.0963 1.76531 46.8803C1.76531 47.6803 1.90931 48.2563 2.19731 48.6083C2.49331 48.9603 2.94131 49.1363 3.54131 49.1363C3.91731 49.1363 4.27731 49.0843 4.62131 48.9803C4.74931 48.9403 4.86531 48.9603 4.96931 49.0403C5.07331 49.1123 5.12531 49.2123 5.12531 49.3403C5.12531 49.5003 5.07331 49.6443 4.96931 49.7723C4.86531 49.9003 4.73731 49.9803 4.58531 50.0123C4.20131 50.0843 3.82131 50.1203 3.44531 50.1203Z" fill="#3A3A3A"/>
      <path d="M7.455 50.0003C7.271 50.0003 7.115 49.9363 6.987 49.8083C6.859 49.6803 6.795 49.5283 6.795 49.3523V44.3843C6.795 44.2083 6.855 44.0603 6.975 43.9403C7.103 43.8203 7.255 43.7603 7.431 43.7603C7.607 43.7603 7.755 43.8203 7.875 43.9403C7.995 44.0603 8.055 44.2083 8.055 44.3843V48.2843L8.067 48.2963H8.079L10.491 44.3123C10.723 43.9443 11.055 43.7603 11.487 43.7603C11.671 43.7603 11.827 43.8243 11.955 43.9523C12.083 44.0803 12.147 44.2323 12.147 44.4083V49.3763C12.147 49.5523 12.083 49.7003 11.955 49.8203C11.835 49.9403 11.687 50.0003 11.511 50.0003C11.335 50.0003 11.187 49.9403 11.067 49.8203C10.947 49.7003 10.887 49.5523 10.887 49.3763V45.4763L10.875 45.4643H10.863L8.451 49.4483C8.219 49.8163 7.887 50.0003 7.455 50.0003Z" fill="#3A3A3A"/>
      <path d="M16.4531 50.1203C15.4931 50.1203 14.7531 49.8483 14.2331 49.3043C13.7131 48.7523 13.4531 47.9443 13.4531 46.8803C13.4531 45.8323 13.7051 45.0323 14.2091 44.4803C14.7131 43.9203 15.4211 43.6403 16.3331 43.6403C16.7571 43.6403 17.1611 43.6683 17.5451 43.7243C17.6971 43.7483 17.8211 43.8203 17.9171 43.9403C18.0211 44.0603 18.0731 44.2003 18.0731 44.3603C18.0731 44.4963 18.0171 44.6043 17.9051 44.6843C17.8011 44.7643 17.6811 44.7883 17.5451 44.7563C17.1931 44.6763 16.8211 44.6363 16.4291 44.6363C15.8771 44.6363 15.4611 44.8163 15.1811 45.1763C14.9091 45.5283 14.7731 46.0963 14.7731 46.8803C14.7731 47.6803 14.9171 48.2563 15.2051 48.6083C15.5011 48.9603 15.9491 49.1363 16.5491 49.1363C16.9251 49.1363 17.2851 49.0843 17.6291 48.9803C17.7571 48.9403 17.8731 48.9603 17.9771 49.0403C18.0811 49.1123 18.1331 49.2123 18.1331 49.3403C18.1331 49.5003 18.0811 49.6443 17.9771 49.7723C17.8731 49.9003 17.7451 49.9803 17.5931 50.0123C17.2091 50.0843 16.8291 50.1203 16.4531 50.1203Z" fill="#3A3A3A"/>
      <path d="M19.9228 44.7323C19.7948 44.7323 19.6828 44.6883 19.5868 44.6003C19.4908 44.5043 19.4428 44.3883 19.4428 44.2523C19.4428 44.1163 19.4908 44.0003 19.5868 43.9043C19.6828 43.8083 19.7948 43.7603 19.9228 43.7603H24.3868C24.5148 43.7603 24.6268 43.8083 24.7228 43.9043C24.8268 44.0003 24.8788 44.1163 24.8788 44.2523C24.8788 44.3803 24.8308 44.4923 24.7348 44.5883C24.6388 44.6843 24.5228 44.7323 24.3868 44.7323H22.9108C22.8388 44.7323 22.8028 44.7683 22.8028 44.8403V49.3523C22.8028 49.5283 22.7388 49.6803 22.6108 49.8083C22.4828 49.9363 22.3308 50.0003 22.1548 50.0003C21.9788 50.0003 21.8268 49.9363 21.6988 49.8083C21.5708 49.6803 21.5068 49.5283 21.5068 49.3523V44.8403C21.5068 44.7683 21.4708 44.7323 21.3988 44.7323H19.9228Z" fill="#3A3A3A"/>
      <path d="M28.6481 44.5763C27.7361 44.5763 27.2241 45.1163 27.1121 46.1963C27.0961 46.2683 27.1281 46.3043 27.2081 46.3043H29.8961C29.9601 46.3043 29.9921 46.2683 29.9921 46.1963C29.9441 45.1163 29.4961 44.5763 28.6481 44.5763ZM28.9481 50.1203C27.9481 50.1203 27.1761 49.8483 26.6321 49.3043C26.0961 48.7603 25.8281 47.9523 25.8281 46.8803C25.8281 45.8083 26.0681 45.0003 26.5481 44.4563C27.0361 43.9123 27.7361 43.6403 28.6481 43.6403C30.3121 43.6403 31.1721 44.6163 31.2281 46.5683C31.2361 46.7603 31.1681 46.9203 31.0241 47.0483C30.8801 47.1763 30.7121 47.2403 30.5201 47.2403H27.1961C27.1321 47.2403 27.1001 47.2723 27.1001 47.3363C27.1961 48.5683 27.8521 49.1843 29.0681 49.1843C29.4921 49.1843 29.9081 49.1163 30.3161 48.9803C30.4361 48.9403 30.5481 48.9563 30.6521 49.0283C30.7561 49.1003 30.8081 49.2003 30.8081 49.3283C30.8081 49.4803 30.7601 49.6163 30.6641 49.7363C30.5681 49.8563 30.4441 49.9363 30.2921 49.9763C29.8521 50.0723 29.4041 50.1203 28.9481 50.1203Z" fill="#3A3A3A"/>
      <path d="M33.6317 49.8203C33.5197 49.9403 33.3797 50.0003 33.2117 50.0003C33.0437 50.0003 32.8997 49.9403 32.7797 49.8203C32.6597 49.7003 32.5997 49.5563 32.5997 49.3883V44.4443C32.5997 44.2603 32.6637 44.1003 32.7917 43.9643C32.9277 43.8283 33.0877 43.7603 33.2717 43.7603H33.3077C33.7637 43.7603 34.0997 43.9603 34.3157 44.3603L35.9477 47.3843C35.9477 47.3923 35.9517 47.3963 35.9597 47.3963L35.9717 47.3843L37.6157 44.3603C37.8317 43.9603 38.1637 43.7603 38.6117 43.7603H38.6717C38.8557 43.7603 39.0117 43.8283 39.1397 43.9643C39.2757 44.1003 39.3437 44.2603 39.3437 44.4443V49.3763C39.3437 49.5443 39.2837 49.6923 39.1637 49.8203C39.0437 49.9403 38.8997 50.0003 38.7317 50.0003C38.5637 50.0003 38.4157 49.9403 38.2877 49.8203C38.1677 49.6923 38.1077 49.5443 38.1077 49.3763V45.3923L38.0957 45.3803H38.0837L36.7517 47.8283C36.5837 48.1403 36.3197 48.2963 35.9597 48.2963C35.6077 48.2963 35.3477 48.1403 35.1797 47.8283L33.8357 45.3803H33.8237L33.8117 45.3923V49.3883C33.8117 49.5563 33.7517 49.7003 33.6317 49.8203Z" fill="#3A3A3A"/>
      <path d="M44.0123 46.8563C43.3563 46.8563 42.8603 46.9683 42.5243 47.1923C42.1963 47.4083 42.0323 47.7043 42.0323 48.0803C42.0323 48.3763 42.1203 48.6203 42.2963 48.8123C42.4723 49.0043 42.6843 49.1003 42.9323 49.1003C43.4603 49.1003 43.8803 48.9363 44.1923 48.6083C44.5123 48.2723 44.6723 47.8163 44.6723 47.2403V46.9523C44.6723 46.8883 44.6363 46.8563 44.5643 46.8563H44.0123ZM42.6323 50.1203C42.0883 50.1203 41.6403 49.9443 41.2883 49.5923C40.9443 49.2403 40.7723 48.7763 40.7723 48.2003C40.7723 47.4963 41.0403 46.9523 41.5763 46.5683C42.1123 46.1763 42.9243 45.9803 44.0123 45.9803H44.5643C44.6363 45.9803 44.6723 45.9443 44.6723 45.8723V45.7403C44.6723 45.3163 44.5723 45.0203 44.3723 44.8523C44.1723 44.6843 43.8123 44.6003 43.2923 44.6003C42.8283 44.6003 42.2763 44.6963 41.6363 44.8883C41.5163 44.9283 41.4003 44.9123 41.2883 44.8403C41.1843 44.7603 41.1323 44.6563 41.1323 44.5283C41.1323 44.3683 41.1803 44.2243 41.2763 44.0963C41.3723 43.9683 41.4963 43.8883 41.6483 43.8563C42.2243 43.7123 42.7723 43.6403 43.2923 43.6403C44.3003 43.6403 44.9923 43.8203 45.3683 44.1803C45.7443 44.5403 45.9323 45.2003 45.9323 46.1603V49.4123C45.9323 49.5723 45.8723 49.7123 45.7523 49.8323C45.6403 49.9443 45.5043 50.0003 45.3443 50.0003C45.1843 50.0003 45.0443 49.9443 44.9243 49.8323C44.8123 49.7123 44.7523 49.5723 44.7443 49.4123L44.7323 49.1123C44.7323 49.1043 44.7283 49.1003 44.7203 49.1003C44.7043 49.1003 44.6963 49.1043 44.6963 49.1123C44.2323 49.7843 43.5443 50.1203 42.6323 50.1203Z" fill="#3A3A3A"/>
      <path d="M52.0078 49.8203C51.8878 49.9403 51.7398 50.0003 51.5638 50.0003C51.3878 50.0003 51.2358 49.9403 51.1078 49.8203C50.9878 49.7003 50.9278 49.5523 50.9278 49.3763V44.4443C50.9278 44.2603 50.9958 44.1003 51.1318 43.9643C51.2678 43.8283 51.4278 43.7603 51.6118 43.7603H55.0918C55.2278 43.7603 55.3478 43.8123 55.4518 43.9163C55.5558 44.0123 55.6078 44.1283 55.6078 44.2643C55.6078 44.4003 55.5558 44.5203 55.4518 44.6243C55.3478 44.7283 55.2278 44.7803 55.0918 44.7803H52.2958C52.2238 44.7803 52.1878 44.8163 52.1878 44.8883V49.3763C52.1878 49.5523 52.1278 49.7003 52.0078 49.8203Z" fill="#3A3A3A"/>
      <path d="M61.0921 46.8803C61.0921 45.3603 60.5401 44.6003 59.4361 44.6003C58.3321 44.6003 57.7801 45.3603 57.7801 46.8803C57.7801 48.4003 58.3321 49.1603 59.4361 49.1603C60.5401 49.1603 61.0921 48.4003 61.0921 46.8803ZM61.5961 49.2803C61.0761 49.8403 60.3561 50.1203 59.4361 50.1203C58.5161 50.1203 57.7961 49.8403 57.2761 49.2803C56.7561 48.7123 56.4961 47.9123 56.4961 46.8803C56.4961 45.8483 56.7561 45.0523 57.2761 44.4923C57.7961 43.9243 58.5161 43.6403 59.4361 43.6403C60.3561 43.6403 61.0761 43.9243 61.5961 44.4923C62.1161 45.0523 62.3761 45.8483 62.3761 46.8803C62.3761 47.9123 62.1161 48.7123 61.5961 49.2803Z" fill="#3A3A3A"/>
      <path d="M63.5815 50.0723C63.4535 50.0883 63.3375 50.0523 63.2335 49.9643C63.1375 49.8763 63.0895 49.7643 63.0895 49.6283C63.0895 49.4923 63.1375 49.3723 63.2335 49.2683C63.3295 49.1643 63.4495 49.1003 63.5935 49.0763C64.0095 48.9963 64.2895 48.7963 64.4335 48.4763C64.5775 48.1563 64.6495 47.5843 64.6495 46.7603V44.4443C64.6495 44.2603 64.7175 44.1003 64.8535 43.9643C64.9895 43.8283 65.1495 43.7603 65.3335 43.7603H68.8255C69.0095 43.7603 69.1695 43.8283 69.3055 43.9643C69.4415 44.1003 69.5095 44.2603 69.5095 44.4443V49.4003C69.5095 49.5683 69.4495 49.7123 69.3295 49.8323C69.2175 49.9443 69.0775 50.0003 68.9095 50.0003C68.7415 50.0003 68.5975 49.9443 68.4775 49.8323C68.3655 49.7123 68.3095 49.5683 68.3095 49.4003V44.8523C68.3095 44.7803 68.2775 44.7443 68.2135 44.7443H65.9215C65.8575 44.7443 65.8255 44.7803 65.8255 44.8523V46.9763C65.8255 48.0643 65.6655 48.8283 65.3455 49.2683C65.0335 49.7003 64.4455 49.9683 63.5815 50.0723Z" fill="#3A3A3A"/>
      <path d="M75.4124 46.8803C75.4124 45.3603 74.8604 44.6003 73.7564 44.6003C72.6524 44.6003 72.1004 45.3603 72.1004 46.8803C72.1004 48.4003 72.6524 49.1603 73.7564 49.1603C74.8604 49.1603 75.4124 48.4003 75.4124 46.8803ZM75.9164 49.2803C75.3964 49.8403 74.6764 50.1203 73.7564 50.1203C72.8364 50.1203 72.1164 49.8403 71.5964 49.2803C71.0764 48.7123 70.8164 47.9123 70.8164 46.8803C70.8164 45.8483 71.0764 45.0523 71.5964 44.4923C72.1164 43.9243 72.8364 43.6403 73.7564 43.6403C74.6764 43.6403 75.3964 43.9243 75.9164 44.4923C76.4364 45.0523 76.6964 45.8483 76.6964 46.8803C76.6964 47.9123 76.4364 48.7123 75.9164 49.2803Z" fill="#3A3A3A"/>
      <path d="M80.5898 50.1203C79.6298 50.1203 78.8898 49.8483 78.3698 49.3043C77.8498 48.7523 77.5898 47.9443 77.5898 46.8803C77.5898 45.8323 77.8418 45.0323 78.3458 44.4803C78.8498 43.9203 79.5578 43.6403 80.4698 43.6403C80.8938 43.6403 81.2978 43.6683 81.6818 43.7243C81.8338 43.7483 81.9578 43.8203 82.0538 43.9403C82.1578 44.0603 82.2098 44.2003 82.2098 44.3603C82.2098 44.4963 82.1538 44.6043 82.0418 44.6843C81.9378 44.7643 81.8178 44.7883 81.6818 44.7563C81.3298 44.6763 80.9578 44.6363 80.5658 44.6363C80.0138 44.6363 79.5978 44.8163 79.3178 45.1763C79.0458 45.5283 78.9098 46.0963 78.9098 46.8803C78.9098 47.6803 79.0538 48.2563 79.3418 48.6083C79.6378 48.9603 80.0858 49.1363 80.6858 49.1363C81.0618 49.1363 81.4218 49.0843 81.7658 48.9803C81.8938 48.9403 82.0098 48.9603 82.1138 49.0403C82.2178 49.1123 82.2698 49.2123 82.2698 49.3403C82.2698 49.5003 82.2178 49.6443 82.1138 49.7723C82.0098 49.9003 81.8818 49.9803 81.7298 50.0123C81.3458 50.0843 80.9658 50.1203 80.5898 50.1203Z" fill="#3A3A3A"/>
      <path d="M88.1155 46.8803C88.1155 45.3603 87.5635 44.6003 86.4595 44.6003C85.3555 44.6003 84.8035 45.3603 84.8035 46.8803C84.8035 48.4003 85.3555 49.1603 86.4595 49.1603C87.5635 49.1603 88.1155 48.4003 88.1155 46.8803ZM88.6195 49.2803C88.0995 49.8403 87.3795 50.1203 86.4595 50.1203C85.5395 50.1203 84.8195 49.8403 84.2995 49.2803C83.7795 48.7123 83.5195 47.9123 83.5195 46.8803C83.5195 45.8483 83.7795 45.0523 84.2995 44.4923C84.8195 43.9243 85.5395 43.6403 86.4595 43.6403C87.3795 43.6403 88.0995 43.9243 88.6195 44.4923C89.1395 45.0523 89.3995 45.8483 89.3995 46.8803C89.3995 47.9123 89.1395 48.7123 88.6195 49.2803Z" fill="#3A3A3A"/>
      <path d="M91.973 47.1923V49.0883C91.973 49.1603 92.001 49.2003 92.057 49.2083C92.345 49.2403 92.569 49.2563 92.729 49.2563C93.873 49.2563 94.445 48.8763 94.445 48.1163C94.445 47.4283 93.849 47.0843 92.657 47.0843H92.069C92.005 47.0843 91.973 47.1203 91.973 47.1923ZM91.973 44.6723V46.1843C91.973 46.2563 92.005 46.2923 92.069 46.2923H92.633C93.121 46.2923 93.497 46.2123 93.761 46.0523C94.033 45.8843 94.169 45.6523 94.169 45.3563C94.169 44.7883 93.673 44.5043 92.681 44.5043C92.529 44.5043 92.325 44.5203 92.069 44.5523C92.005 44.5683 91.973 44.6083 91.973 44.6723ZM91.397 50.0243C91.205 50.0083 91.041 49.9283 90.905 49.7843C90.777 49.6403 90.713 49.4683 90.713 49.2683V44.4923C90.713 44.2923 90.777 44.1203 90.905 43.9763C91.033 43.8243 91.193 43.7403 91.385 43.7243C91.729 43.6923 92.125 43.6763 92.573 43.6763C94.421 43.6763 95.345 44.1883 95.345 45.2123C95.345 45.5723 95.221 45.8763 94.973 46.1243C94.725 46.3723 94.377 46.5403 93.929 46.6283L93.917 46.6403H93.929C94.481 46.7203 94.905 46.9003 95.201 47.1803C95.505 47.4523 95.657 47.7923 95.657 48.2003C95.657 49.4563 94.665 50.0843 92.681 50.0843C92.289 50.0843 91.861 50.0643 91.397 50.0243Z" fill="#3A3A3A"/>
      <path d="M99.9694 46.8563C99.3134 46.8563 98.8174 46.9683 98.4814 47.1923C98.1534 47.4083 97.9894 47.7043 97.9894 48.0803C97.9894 48.3763 98.0774 48.6203 98.2534 48.8123C98.4294 49.0043 98.6414 49.1003 98.8894 49.1003C99.4174 49.1003 99.8374 48.9363 100.149 48.6083C100.469 48.2723 100.629 47.8163 100.629 47.2403V46.9523C100.629 46.8883 100.593 46.8563 100.521 46.8563H99.9694ZM98.5894 50.1203C98.0454 50.1203 97.5974 49.9443 97.2454 49.5923C96.9014 49.2403 96.7294 48.7763 96.7294 48.2003C96.7294 47.4963 96.9974 46.9523 97.5334 46.5683C98.0694 46.1763 98.8814 45.9803 99.9694 45.9803H100.521C100.593 45.9803 100.629 45.9443 100.629 45.8723V45.7403C100.629 45.3163 100.529 45.0203 100.329 44.8523C100.129 44.6843 99.7694 44.6003 99.2494 44.6003C98.7854 44.6003 98.2334 44.6963 97.5934 44.8883C97.4734 44.9283 97.3574 44.9123 97.2454 44.8403C97.1414 44.7603 97.0894 44.6563 97.0894 44.5283C97.0894 44.3683 97.1374 44.2243 97.2334 44.0963C97.3294 43.9683 97.4534 43.8883 97.6054 43.8563C98.1814 43.7123 98.7294 43.6403 99.2494 43.6403C100.257 43.6403 100.949 43.8203 101.325 44.1803C101.701 44.5403 101.889 45.2003 101.889 46.1603V49.4123C101.889 49.5723 101.829 49.7123 101.709 49.8323C101.597 49.9443 101.461 50.0003 101.301 50.0003C101.141 50.0003 101.001 49.9443 100.881 49.8323C100.769 49.7123 100.709 49.5723 100.701 49.4123L100.689 49.1123C100.689 49.1043 100.685 49.1003 100.677 49.1003C100.661 49.1003 100.653 49.1043 100.653 49.1123C100.189 49.7843 99.5014 50.1203 98.5894 50.1203Z" fill="#3A3A3A"/>
      <path d="M104.649 49.8203C104.529 49.9403 104.381 50.0003 104.205 50.0003C104.029 50.0003 103.877 49.9403 103.749 49.8203C103.621 49.6923 103.557 49.5403 103.557 49.3643V44.3963C103.557 44.2203 103.621 44.0723 103.749 43.9523C103.877 43.8243 104.029 43.7603 104.205 43.7603C104.381 43.7603 104.529 43.8243 104.649 43.9523C104.777 44.0723 104.841 44.2203 104.841 44.3963V46.1363C104.841 46.2003 104.873 46.2323 104.937 46.2323H107.481C107.545 46.2323 107.577 46.2003 107.577 46.1363V44.3963C107.577 44.2203 107.637 44.0723 107.757 43.9523C107.885 43.8243 108.037 43.7603 108.213 43.7603C108.389 43.7603 108.541 43.8243 108.669 43.9523C108.797 44.0723 108.861 44.2203 108.861 44.3963V49.3643C108.861 49.5403 108.797 49.6923 108.669 49.8203C108.541 49.9403 108.389 50.0003 108.213 50.0003C108.037 50.0003 107.885 49.9403 107.757 49.8203C107.637 49.6923 107.577 49.5403 107.577 49.3643V47.2403C107.577 47.1763 107.545 47.1443 107.481 47.1443H104.937C104.873 47.1443 104.841 47.1763 104.841 47.2403V49.3643C104.841 49.5403 104.777 49.6923 104.649 49.8203Z" fill="#3A3A3A"/>
      <path d="M111.248 50.0003C111.064 50.0003 110.908 49.9363 110.78 49.8083C110.652 49.6803 110.588 49.5283 110.588 49.3523V44.3843C110.588 44.2083 110.648 44.0603 110.768 43.9403C110.896 43.8203 111.048 43.7603 111.224 43.7603C111.4 43.7603 111.548 43.8203 111.668 43.9403C111.788 44.0603 111.848 44.2083 111.848 44.3843V48.2843L111.86 48.2963H111.872L114.284 44.3123C114.516 43.9443 114.848 43.7603 115.28 43.7603C115.464 43.7603 115.62 43.8243 115.748 43.9523C115.876 44.0803 115.94 44.2323 115.94 44.4083V49.3763C115.94 49.5523 115.876 49.7003 115.748 49.8203C115.628 49.9403 115.48 50.0003 115.304 50.0003C115.128 50.0003 114.98 49.9403 114.86 49.8203C114.74 49.7003 114.68 49.5523 114.68 49.3763V45.4763L114.668 45.4643H114.656L112.244 49.4483C112.012 49.8163 111.68 50.0003 111.248 50.0003Z" fill="#3A3A3A"/>
      <path d="M120.198 44.5883C119.046 44.5883 118.47 44.9203 118.47 45.5843C118.47 46.2563 119.078 46.5923 120.294 46.5923H120.906C120.978 46.5923 121.014 46.5603 121.014 46.4963V44.7443C121.014 44.6803 120.978 44.6403 120.906 44.6243C120.666 44.6003 120.43 44.5883 120.198 44.5883ZM118.842 47.0843C118.386 46.9723 118.01 46.7803 117.714 46.5083C117.418 46.2283 117.27 45.8803 117.27 45.4643C117.27 44.2723 118.23 43.6763 120.15 43.6763C120.598 43.6763 121.082 43.6963 121.602 43.7363C121.794 43.7523 121.95 43.8323 122.07 43.9763C122.198 44.1203 122.262 44.2883 122.262 44.4803V49.3763C122.262 49.5523 122.202 49.7003 122.082 49.8203C121.962 49.9403 121.814 50.0003 121.638 50.0003C121.462 50.0003 121.314 49.9403 121.194 49.8203C121.074 49.7003 121.014 49.5523 121.014 49.3763V47.5883C121.014 47.5163 120.978 47.4803 120.906 47.4803H120.342C119.966 47.4803 119.706 47.5283 119.562 47.6243C119.426 47.7123 119.266 47.9443 119.082 48.3203L118.578 49.4363C118.498 49.6043 118.378 49.7403 118.218 49.8443C118.058 49.9483 117.882 50.0003 117.69 50.0003C117.546 50.0003 117.434 49.9403 117.354 49.8203C117.282 49.7003 117.278 49.5723 117.342 49.4363L117.906 48.2483C118.074 47.8963 118.226 47.6403 118.362 47.4803C118.498 47.3123 118.658 47.1883 118.842 47.1083C118.858 47.1083 118.866 47.1043 118.866 47.0963C118.866 47.0883 118.858 47.0843 118.842 47.0843Z" fill="#3A3A3A"/>
    </svg>
    <form @submit.prevent="handleLogin">
      <div>
        <label for="username">логин</label>
        <input
            type="text"
            id="username"
            v-model="username"
            required
        />
      </div>
      <div>
        <label for="password">пароль</label>
        <input
            type="password"
            id="password"
            v-model="password"
            required
        />
      </div>
      <button type="submit">войти</button>
    </form>
    <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import store from "@/store";

export default {
  setup() {
    const username = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const router = useRouter();
    const errorTimeout = ref(null);

    const handleLogin = async () => {
      try {
        const response = await axios.post('https://unesco.magnatmedia.com/api/auth/login', {
          username: username.value,
          password: password.value,
        }, {withCredentials:true});
        store.state.user = response.data;
        router.push({ name: 'cabinet' });
      } catch (error) {
        console.log(error)
        clearTimeout(errorTimeout);
        errorMessage.value = 'Неверный логин и/или пароль';
        setTimeout(()=>{
          errorMessage.value = '';
        },5000)
      }
    };

    return {
      username,
      password,
      errorMessage,
      handleLogin,
    };
  },
};
</script>

<style scoped lang="scss">
.login {
  &-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 260px;
    transform: translateX(-50%);

    svg {
      height: 64px;
      display: block;
      margin: 0 auto 80px;
    }

    div {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-bottom: 16px;

      label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #3A3A3A;
        margin-bottom: 8px;
      }
      input {
        font-family: "M PLUS Rounded 1c", system-ui;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #3A3A3A;
        background-color: #FFFFFF;
        padding: 8px 12px;
        border-radius: 8px;
        border: 0;
        outline: 0;
        width: 100%;
      }
    }

    button {
      width: 100%;
      text-align: center;
      font-family: "M PLUS Rounded 1c", system-ui;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #3A3A3A;
      background-color: #FFFFFF;
      padding: 8px 12px;
      border-radius: 8px;
      border: 0;
      outline: 0;
      margin-top: 8px;

      &:hover {
        background-color: #3A3A3A;
        color: #FFFFFF;
      }
    }


    .error {
      color: #FA4646;
      background-color: rgba(#FA4646, .1);
      border-radius: 8px;
      padding: 12px;
      border: 1px solid #FA4646;
      margin-top: 24px;
      font-size: 12px;
    }
  }
}
</style>
